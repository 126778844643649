.message-input {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  padding: 10px;
  background-color: #282828;
  border-top: 1px solid #333;
  position: sticky; /* Make the input sticky */
  bottom: 0; /* Stick to the bottom of the window */
  z-index: 100; /* Ensure it stays above other content */
}

.message-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Adjust this percentage as needed */
  max-width: 800px; /* Optional: Set a maximum width */
}

.message-input textarea {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: white;
  line-height: 1.5;
  overflow-y: auto; /* Allow vertical scrolling */
  max-height: 150px; /* Limit the maximum height of the input */
  resize: none; /* Disable manual resizing */
  width: 100%; /* Take up the full width of the container */
  
  /* Hide the scrollbar */
  scrollbar-width: none; /* For Firefox */
}

.message-input textarea::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.message-input button {
  margin-left: 10px;
  padding: 10px;
  background-color: #555454;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.message-input button:hover {
  background-color: #b8b8b8;
}
