.homepage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    color: white;
    text-align: center;
  }
  
  h1 {
    margin-bottom: 40px; /* Increased margin to give more space between the title and cards */
    font-size: 2rem; /* Adjusted font size for the title */
  }
  
  .options-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%; /* Ensures the grid takes up full width */
    max-width: 800px; /* Centers the grid with a max width */
  }
  
  .option-card {
    padding: 30px 20px; /* Increased padding */
    background-color: #b061e9;
    border-radius: 15px;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
    min-height: 150px; /* Set a minimum height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .option-card:hover {
    transform: translateY(-5px);
    background-color: #b061e9;
  }
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 30px; /* Increased margin for more space between title and description */
    text-align: left; /* Align text to the left */
  }
  
  p {
    font-size: 1rem;
    margin: 0;
    text-align: left; /* Align text to the left */
  }
  