/* General Styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #121212; /* Ensure the background color matches the app */
  color: white;
  font-family: Arial, sans-serif;
}

.app {
  display: flex;
  height: 100vh; /* Ensures the app takes full viewport height */
  background-color: #121212;
  color: white;
  overflow: hidden; /* Prevents any overflow issues */
}

.chat-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #282828;
}

/* .app {
  display: flex;
  height: 100vh;
  background-color: #121212;
  color: white;
  overflow: hidden;
  width: 100vw;
}

.chat-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #282828;
  overflow-y: hidden;
  position: relative;
  width: 100%;
} */
