.chat-window {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #282828;
  color: white;
  overflow-y: auto;
  scrollbar-width: thin; 
  scrollbar-color: #4a4a4a #282828; 
  width: 100%; 
  box-sizing: border-box; 
  position: relative;
}

.chat-window::-webkit-scrollbar {
  width: 12px; 
}

.chat-window::-webkit-scrollbar-track {
  background: #282828; 
}

.chat-window::-webkit-scrollbar-thumb {
  background-color: #4a4a4a; 
  border-radius: 10px; 
  border: 3px solid #282828; 
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%; 
  max-width: 800px; 
  margin: 0 auto; 
  padding-bottom: 10px; 
  position: relative;
}

/* .chat-window {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #282828;
  color: white;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4a4a4a #282828;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.chat-container {
  display: flex;
  flex-direction: column;
  background-color: #282828;
  flex-grow: 1;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 10px;
  position: relative;
} */

.intro-box {
  background-color: #3c3c3c;
  padding: 30px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  margin: auto; /* Centers the intro box horizontally */
  text-align: center;
  position: absolute; /* Positions it relative to the container */
  top: 50%; /* Centers vertically */
  left: 50%;
  transform: translate(-50%, -50%); /* Adjusts position to truly center */
  z-index: 1; /* Ensures it is on top of other content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  max-width: 70%; 
}

.message.user {
  background-color: #83609c;
  align-self: flex-end; 
}

.message.assistant {
  background-color: #3c3c3c;
  align-self: flex-start; 
}

.empty-line {
  margin-bottom: 10px; /* Adjust the value as needed */
}
