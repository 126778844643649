.sidebar {
  width: 250px; /* Fixed width */
  background-color: #1c1c1c;
  color: white;
  padding: 10px;
  height: 100vh;
  flex-shrink: 0; /* Prevent the sidebar from shrinking */
  overflow-y: auto; /* Allows scrolling if content overflows vertically */
}

.home-button {
  background-color: #3c3c3c;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
}

.sidebar h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0; /* Remove default margin */
}

.sidebar li {
  padding: 10px;
  margin-bottom: 5px;
  background-color: #2c2c2c;
  cursor: pointer;
  border-radius: 5px;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}

.sidebar li:hover {
  background-color: #3c3c3c;
}
